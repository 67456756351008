<template>
  <div class="columns">
    <div class="column is-narrow">
      <div class="field">
        <form @submit.prevent="add">
          <input
            type="text"
            class="input"
            v-model="search"
            placeholder="Add a keyword & press enter to search"
          />
        </form>
      </div>
      <p class="help">
        To search for multiple keywords, press enter & add another
      </p>
      <p class="help">
        <span> To show adverts containing</span
        ><span class="has-text-weight-bold"> all </span
        ><span>keywords, use a comma delimited list: </span>
        <code>classiche,1989,non cat</code>
      </p>
      <p class="help">
        <span>To exclude a keyword use a minus: </span>
        <code>-xenon</code>
      </p>
    </div>
    <div class="column">
      <transition-group
        v-if="keywords && keywords.length"
        class="field is-grouped is-grouped-multiline"
        name="flip-list"
        tag="div"
      >
        <div
          class="control"
          v-for="(keyword, index) in keywords"
          :key="`key-${index}`"
        >
          <div class="field has-addons is-marginless">
            <div class="tags has-addons">
              <span
                class="tag has-text-weight-bold"
                :class="getTagColour(keyword)"
                >{{ keyword }}</span
              >
              <a
                class="tag is-delete has-text-danger"
                @click="removeKeyword(index)"
              />
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'ExplorerKeyword',
  data: () => ({ search: '' }),
  computed: {
    ...mapGetters('explorer', ['keywords'])
  },
  methods: {
    ...mapMutations('explorer', ['addKeyword', 'removeKeyword']),
    add() {
      if (this.search) {
        this.$mxp.track('explorer_keyword_filter', { keyword: this.search })
        this.addKeyword(this.search)
        this.search = ''
      }
    },
    getTagColour(keyword) {
      if (keyword[0] === '-') return 'is-warning'
      else if (keyword.includes(',')) return 'is-info'
      else return 'is-soft'
    }
  }
}
</script>
